require(["jquery", "domReady!"], function($, doc) {
	// Enable step elements only once DOM ready
	$('.domready-disabled').each(function(index, element) {
		var $e = $(element);
		if ($e.prop('disabled')) {
			$e.removeProp('disabled');
			$e.removeClass('domready-disabled');
		}
	});

	function fixIframeSize(){
        var windowWidth=jQuery(window).width();
        var windowHeight=jQuery(window).height();
        if (jQuery(window).width() > 1023) {
        	var iframeSetwidth=windowWidth*0.66666666;
        }else{
        	var iframeSetwidth=windowWidth*0.9;
        }
        var iframeHeight=(iframeSetwidth/16)*9;
        var heightPosition= (windowHeight - iframeHeight)/2;
        jQuery('.cms-home .home-section-4 iframe').height(iframeHeight).width(iframeSetwidth).css('margin', heightPosition+'px auto 0');
    }
    function myAccountMobile(){
    	jQuery('.header .authorization-link').click(function(){
    			if(jQuery('.header .authorization-link.my-account .account-box.dropdown').is(':visible')){
		    		jQuery('.header .authorization-link.my-account .account-box.dropdown').slideUp();
		    		jQuery(this).removeClass('show');
		    	}else {
		    		jQuery('.header .authorization-link.my-account .account-box.dropdown').slideDown();
		    		jQuery(this).addClass('show');
		    	}	
    	});	
	}
	$(document).ready(function(){
		$('.header-nav-layer').click(function(){
				$('.header-nav-layer').removeClass('overlay');
				$('.header-nav-toggle').removeClass('slide-out');
		});
		$('.action.nav-toggle').click(function(){
			$('.header-nav-layer').addClass('overlay');
			$('.header-nav-toggle').addClass('slide-out');
		});
		fixIframeSize();
		myAccountMobile();

		// Page Customer Create - Button create account become green after all input filled
		var checkInput=0;
		$('.customer-account-create .form-create-account .field.required input').keyup(function(){
			$('.customer-account-create .form-create-account .field.required input').each(function(){
				if(!$(this).val()){checkInput=0;}else{checkInput=1;}
			});
			if (checkInput==1) {
				$('.customer-account-create .form-create-account .actions-toolbar button').removeClass('submit');
			}else{
				$('.customer-account-create .form-create-account .actions-toolbar button').addClass('submit');
			}
		});

		// var checkExist = setInterval(function() {
		//     if ($('.customer-account-index .page.messages .action').length) {
		//     	$('.customer-account-index .page.messages').addClass('has-button');
		//    		$('.customer-account-index .page.messages.has-button .message.success .action').insertAfter('.customer-account-index .page.messages.has-button .success');
		//    		$('.customer-account-index .page.messages.has-button .message.success .action').remove();
		//         clearInterval(checkExist);
		//     }
		// }, 25);//every 10ms
		
	});
	$(window).resize(function(){
		fixIframeSize();
		if (jQuery(window).width() > 1023) {
			jQuery('.header .authorization-link.my-account .account-box.dropdown').removeAttr('style');
	    	jQuery('.header .authorization-link.my-account').removeClass('show');
		}
	});
	$(window).on('orientationchange',function(){
		fixIframeSize();
		if (jQuery(window).width() > 1023) {
			jQuery('.header .authorization-link.my-account .account-box.dropdown').removeAttr('style');
	    	jQuery('.header .authorization-link.my-account').removeClass('show');
		}
	});
})();